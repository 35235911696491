//
import { ROUTES_ADMIN } from "@milize/common/constants/routes.constant";
import { loginSuccess } from "features/auth/slice";
import app from "libs/feathers";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

const CommonRoute = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleAuthenticate = async () => {
      const accessToken = await app.authentication.getAccessToken();
      if (accessToken) {
        try {
          const userData = await app.reAuthenticate();
          dispatch(loginSuccess({ user: userData.user }));
          navigate(ROUTES_ADMIN.HOME);
          // eslint-disable-next-line no-empty
        } catch (error) {}
      }
    };

    handleAuthenticate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Outlet />;
};

export default CommonRoute;
