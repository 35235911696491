import { ROUTES_ADMIN } from "@milize/common/constants/routes.constant";
import { loginSuccess } from "features/auth/slice";
import feathers from "libs/feathers";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export type ReceivedProps = Record<string, never>;

const useHook = (props: ReceivedProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthenticate = async () => {
      try {
        const accessToken = await feathers.authentication.getAccessToken();

        if (!accessToken) {
          navigate(ROUTES_ADMIN.SIGN_IN);
          return;
        }

        const { user } = await feathers.authenticate({
          strategy: "jwt",
          accessToken,
        });

        dispatch(loginSuccess({ user }));
      } catch {
        navigate(ROUTES_ADMIN.SIGN_IN);
      }
    };

    handleAuthenticate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ...props,
  };
};

export type Props = ReturnType<typeof useHook>;

export default useHook;
