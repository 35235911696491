import { USER_ACTIVE_STATUS } from "@milize/common/constants";
import { User } from "@milize/common/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  userList: User[];
}

const initialState: InitialState = {
  userList: [],
};

const userManageSlice = createSlice({
  name: "userManage",
  initialState,
  reducers: {
    setUserList: (state, { payload }: PayloadAction<User[]>) => {
      state.userList = payload;
    },
    approveUser: (state, { payload: id }: PayloadAction<number>) => {
      const indexAcc = state.userList.findIndex((it) => it.id === id);
      state.userList[indexAcc].status = USER_ACTIVE_STATUS.ACTIVE;
    },
    rejectUser: (state, { payload: id }: PayloadAction<number>) => {
      state.userList = state.userList.filter((it) => it.id !== id);
    },
  },
});

export const { setUserList, approveUser, rejectUser } = userManageSlice.actions;

export default userManageSlice;
