import { FC } from "react";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { LayoutWrapper } from "./styles";
import useHook, { Props, ReceivedProps } from "./hook";

const MainLayout: FC<Props> = () => {
  return (
    <LayoutWrapper hasSider>
      <Sidebar />
      <Layout>
        <Header />
        <Layout.Content>
          <Outlet />
        </Layout.Content>
      </Layout>
    </LayoutWrapper>
  );
};

const AppLayout: FC<ReceivedProps> = (props) => (
  <MainLayout {...useHook(props)} />
);

export default AppLayout;
