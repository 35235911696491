import { LOCALE } from "@milize/common/constants";
import EnLang from "./entries/en-US";
import JaLang from "./entries/ja-JP";

const AppLocale: Record<keyof typeof LOCALE, typeof EnLang | typeof JaLang> = {
  en: EnLang,
  ja: JaLang,
};

export const DEFAULT_LOCALE = "ja";

export default AppLocale;
