import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type State = {
  userCount: number;
  fpCount: number;
  operatorCount: number;
  contractCount: number;
};

const initialState: State = {
  userCount: 0,
  fpCount: 0,
  operatorCount: 0,
  contractCount: 0,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setUserCount(state, action: PayloadAction<{ userCount: number }>) {
      const { userCount } = action.payload;
      state.userCount = userCount;
    },
  },
});

export default dashboardSlice;

export const { setUserCount } = dashboardSlice.actions;
