import { HEADER_MENU_ADMIN } from "@milize/common/constants/routes.constant";
import { useMatchRoute } from "features/auth/hooks";
import { useIntl } from "react-intl";
import Avatar from "./Avatar";
import { HeaderWrapper } from "./styles";

const Header = () => {
  const { formatMessage: t } = useIntl();
  const { isMatch } = useMatchRoute();

  const getTitle = () => {
    const route = HEADER_MENU_ADMIN.find((it) => isMatch(it.path));
    return route ? t({ id: route.label }) : "";
  };

  return (
    <HeaderWrapper>
      <h1>{getTitle()}</h1>
      <Avatar />
    </HeaderWrapper>
  );
};

export default Header;
