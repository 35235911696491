import { USER_ROLES } from "@milize/common/constants";
import { ROUTES_ADMIN } from "@milize/common/constants/routes.constant";
import { CommonRoute, Layout } from "components/common";
import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const SignInPage = lazy(() => import("features/auth/SignIn"));
const SettingsPage = lazy(() => import("features/settings"));
const UserListPage = lazy(() => import("features/userManagement/Users"));
const AdminUserListPage = lazy(
  () => import("features/userManagement/AdminUserList")
);
const NotFoundPage = lazy(() => import("features/auth/404"));
const OverviewPage = lazy(() => import("features/dashboard"));
const ContractList = lazy(() => import("features/contracts/ContractList"));
const CompanyList = lazy(() => import("features/companyList"));
const ContractTypeList = lazy(() => import("features/contractType"));
const ContractDetailPage = lazy(
  () => import("features/contracts/ContractDetail")
);

const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={null}>
        <Routes>
          <Route element={<CommonRoute />}>
            <Route path={ROUTES_ADMIN.SIGN_IN} element={<SignInPage />} />
          </Route>

          <Route path={ROUTES_ADMIN.HOME} element={<Layout />}>
            <Route index element={<OverviewPage />} />
            <Route
              path={ROUTES_ADMIN.SETTINGS}
              element={
                <Suspense fallback={null}>
                  <SettingsPage />
                </Suspense>
              }
            />
            <Route
              path={ROUTES_ADMIN.USERS}
              element={
                <Suspense fallback={null}>
                  <UserListPage />
                </Suspense>
              }
            />
            <Route
              path={ROUTES_ADMIN.FPS}
              element={
                <Suspense fallback={null}>
                  <AdminUserListPage role={USER_ROLES.FP} />
                </Suspense>
              }
            />
            <Route
              path={ROUTES_ADMIN.OPERATORS}
              element={
                <Suspense fallback={null}>
                  <AdminUserListPage role={USER_ROLES.OPERATOR} />
                </Suspense>
              }
            />
            <Route
              path={ROUTES_ADMIN.CONTRACTS}
              element={
                <Suspense fallback={null}>
                  <ContractList />
                </Suspense>
              }
            />
            <Route
              path={ROUTES_ADMIN.DETAIL_CONTRACT}
              element={
                <Suspense fallback={null}>
                  <ContractDetailPage />
                </Suspense>
              }
            />
            <Route
              path={ROUTES_ADMIN.COMPANIES}
              element={
                <Suspense fallback={null}>
                  <CompanyList />
                </Suspense>
              }
            />
            <Route
              path={ROUTES_ADMIN.CONTRACT_TYPE}
              element={
                <Suspense fallback={null}>
                  <ContractTypeList />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="*"
            element={
              <Suspense fallback={null}>
                <NotFoundPage />
              </Suspense>
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRoutes;
