import { ROUTES_ADMIN } from "@milize/common/constants/routes.constant";
import { Modal } from "antd";
import feathers from "libs/feathers";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { errorsAuthSelector } from "./selectors";
import { UserLogin } from "./SignIn/validation";
import { loginSuccess, logout, setErrorsAuth } from "./slice";

export const useMatchRoute = () => {
  const { pathname } = useLocation();

  const isMatch = (path?: string) =>
    path &&
    ((path === ROUTES_ADMIN.HOME && pathname === ROUTES_ADMIN.HOME) ||
      (path && path !== ROUTES_ADMIN.HOME && pathname.startsWith(path)));

  return { isMatch };
};

export const useErrorsAuth = () => {
  const dispatch = useDispatch();
  const errorsAuth = useSelector(errorsAuthSelector);

  const resetErrorsAuth = () => {
    dispatch(setErrorsAuth(null));
  };

  return { errorsAuth, resetErrorsAuth };
};

export const useLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState({
    loading: false,
    error: null,
  });

  const handleLogin = async ({ email, password }: UserLogin) => {
    try {
      setState({ ...state, loading: true });

      const { user } = await feathers.authenticate({
        email,
        password,
        strategy: "local",
      });

      dispatch(loginSuccess({ user }));
      setState((prev) => ({ ...prev, loading: false }));
      navigate(ROUTES_ADMIN.HOME);
    } catch (error: any) {
      dispatch(setErrorsAuth(error));
      setState((prev) => ({ ...prev, loading: false, error }));
    }
  };

  return { ...state, handleLogin };
};

export const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { messages } = useIntl();
  const handleLogout = async () => {
    try {
      await feathers.logout();
      dispatch(logout());
      navigate(ROUTES_ADMIN.SIGN_IN);
    } catch (error: any) {
      const errorMessage = error?.errors?.[0]?.message || error?.message;
      Modal.warning({
        content: messages[errorMessage] || errorMessage,
      });
    }
  };

  return { handleLogout };
};
