import { Input } from "formik-antd";
import styled, { css } from "styled-components";

const stylesInput = css`
  height: 42px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.gray["100"]};
  border-color: ${({ theme }) => theme.colors.gray["200"]};
  .anticon svg {
    font-size: 23px;
  }
`;

export const InputWrapper = styled(Input)`
  ${stylesInput}
`;

export const InputPasswordWrapper = styled(Input.Password)`
  ${stylesInput}
`;

export const InputTextareaWrapper = styled(Input.TextArea)`
  ${stylesInput}
`;
