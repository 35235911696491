import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authSlice from "features/auth/slice";
import userManageSlice from "features/userManagement/slice";
import contractSlice from "features/contracts/slice";
import dashboardSlice from "features/dashboard/slice";

const rootReducer = combineReducers({
  [authSlice.name]: authSlice.reducer,
  [userManageSlice.name]: userManageSlice.reducer,
  [contractSlice.name]: contractSlice.reducer,
  [dashboardSlice.name]: dashboardSlice.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
