import { FormProps } from "antd";
import { FC } from "react";
import LogoSrc from "assets/images/logo_yoshinani.svg";
import { StyledFormAuth } from "./styles";

interface IProps extends FormProps {
  formTitle: string;
  formDesc?: string;
}

const FormAuth: FC<IProps> = ({
  formTitle,
  formDesc,
  children,
  layout = "vertical",
  ...rest
}) => {
  return (
    <StyledFormAuth layout={layout} {...rest}>
      <img alt="logo" className="form-img" src={LogoSrc} />
      <h2 className="form-title">{formTitle}</h2>
      {formDesc && <p className="form-desc">{formDesc}</p>}
      {children}
    </StyledFormAuth>
  );
};

export default FormAuth;
