import feathers from "@feathersjs/feathers";
import auth from "@feathersjs/authentication-client";
import feathersRest from "@feathersjs/rest-client";
import { API_ENDPOINTS, TOKEN_STORED_KEY } from "@milize/common/constants";
import axios from "./axios";

const API_URL = process.env.REACT_APP_API_URL as string;

const app = feathers();

const restClient = feathersRest(API_URL);

app.configure(restClient.axios(axios));
app.configure(
  auth({
    storageKey: TOKEN_STORED_KEY,
  })
);

export const usersServices = app.service(API_ENDPOINTS.USERS);
export const approveService = app.service(API_ENDPOINTS.APPROVE);
// export const usersServices = app.service(API_ENDPOINTS.USERS);

export const registerService = app.service(API_ENDPOINTS.REGISTER);
export const resendVerifyService = app.service(API_ENDPOINTS.RESEND_VERIFY);
export const verifyMailService = app.service(API_ENDPOINTS.USER_ACTIVE);
export const contractsService = app.service(API_ENDPOINTS.CONTRACTS);
export const usersService = app.service(API_ENDPOINTS.USERS);
export const commentControlService = app.service(API_ENDPOINTS.COMMENT_CONTROL);
export const commentReadersService = app.service(API_ENDPOINTS.COMMENT_READERS);
export const commentService = app.service(API_ENDPOINTS.COMMENTS);
export const ContractMetadataService = app.service(
  API_ENDPOINTS.CONTRACT_METADATA
);
export const settingsService = app.service(API_ENDPOINTS.SETTINGS);
export const analyticsServicer = app.service(API_ENDPOINTS.ANALYTICS);
export const userStatisticService = app.service(API_ENDPOINTS.USER_STATISTIC);
export const companiesService = app.service(API_ENDPOINTS.COMPANIES);
export const purposeService = app.service(API_ENDPOINTS.CONTRACT_TYPES);

export default app;
