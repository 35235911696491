import { ROUTES_ADMIN } from "@milize/common/constants/routes.constant";
import { TableProps, Tooltip } from "antd";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useLocation, useSearchParams } from "react-router-dom";
import { StyledTable } from "./styles";

enum ORDER {
  ASCEND = "1",
  DESCEND = "-1",
}

type SortOrder = "ascend" | "descend" | undefined;

type TablePropsInner<T = any> = TableProps<T>;

const AntTable: FC<TablePropsInner> = (props) => {
  const { formatMessage: t } = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    field,
    order,
    page = 1,
    size = 10,
    ...rest
  } = Object.fromEntries([...searchParams]);
  const location = useLocation();
  const checkPagination =
    location.pathname === ROUTES_ADMIN.COMPANIES ||
    location.pathname === ROUTES_ADMIN.CONTRACT_TYPE;
  const isCheckTooltip = location.pathname === ROUTES_ADMIN.CONTRACTS;
  const customRow = (tooltip: any) => {
    return (
      <Tooltip color="#eb9500" title={t({ id: "text.contract.detail" })}>
        <tr {...tooltip} />
      </Tooltip>
    );
  };
  const handleChangeTable = (
    pagination: any,
    filter: any,
    sorter: any,
    extra: any
  ) => {
    const query = Object.fromEntries([...searchParams]);
    // change pagination
    if (extra.action === "paginate") {
      const { current, pageSize } = pagination;
      const params = {
        size: pageSize,
        page: +page !== current ? current : 1,
      };
      setSearchParams({ ...query, ...params });
    }
    // change sorter
    else if (extra.action === "sort") {
      const { field: newField, order: newOrder } = sorter;
      if (!newOrder) {
        const resQuery = { ...query };
        delete resQuery.field;
        delete resQuery.order;
        setSearchParams({
          ...resQuery,
        });
      } else {
        setSearchParams({
          ...query,
          field: newField,
          order:
            newField !== field
              ? ORDER.ASCEND
              : order === "ascend"
              ? ORDER.ASCEND
              : ORDER.DESCEND,
        });
      }
    } else {
      const resQuery = { ...query };
      const filterQuery = Object.keys(filter)?.reduce((res, key: string) => {
        if (!filter[key]) {
          delete resQuery[key];
          return res;
        }
        return {
          ...res,
          [key]: filter[key].join(","),
        };
      }, {});
      setSearchParams({ ...resQuery, ...filterQuery, page: "1" });
    }
  };

  const getColumns = () => {
    const { columns = [] } = props;
    const filters: Record<string, any> = Object.keys(rest).reduce(
      (res, key) => ({
        ...res,
        [key]: (rest[key] || "").split(","),
      }),
      {}
    );
    return columns.map((it) => ({
      ...it,
      sortOrder:
        it.key === field && order
          ? order === ORDER.ASCEND
            ? "ascend"
            : ("descend" as SortOrder)
          : undefined,
      ...(it.filters && {
        filteredValue:
          it.key && Object.keys(filters).includes(it.key as string)
            ? filters[it.key]
            : [],
      }),
    }));
  };
  return (
    <StyledTable
      {...props}
      columns={getColumns()}
      onChange={handleChangeTable}
      showSorterTooltip={false}
      scroll={{ x: "max-content", ...props.scroll }}
      pagination={
        checkPagination
          ? false
          : typeof props.pagination === "boolean"
          ? props.pagination
          : {
              showTotal: (total) =>
                t({ id: "pagination.txt.total" }, { total }),
              showSizeChanger: true,
              size: "small",
              current: +page,
              pageSize: +size,
              ...(props.pagination || {}),
            }
      }
      components={{
        body: {
          row: isCheckTooltip ? customRow : undefined,
        },
      }}
    />
  );
};

export default AntTable;
